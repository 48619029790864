<i18n>
{
  "de": {
    "links": {
      "portfolio": "Liegenschaften",
      "report": "Absenkpfad",
      "reida": "Reporting",
      "scenario": "Szenarien",
      "settings": "Einstellungen",
      "license": "Lizenz",
      "import": "Import",
      "account": "Account",
      "portfolioImport": "Portfolio importieren",
      "management": "Verwaltung"
    },
    "loading": "Daten werden geladen...",
    "simulation_pending": "{n} Liegenschaft wird berechnet ... | {n} Liegenschaften werden berechnet ..."
  }
}
</i18n>

<template>
  <header class="compass-header">
    <!-- Global menu -->
    <nav class="primary-nav">
      <router-link id="header-logo" :to="{ path: '/' }">
        <img src="/app_logo.png" width="174" height="40" alt="Co2mpass logo" />
      </router-link>
      <div class="header-buttons">
        <VersionNumber />
        <PortfolioPicker
          v-if="loggedIn && portfolios && portfolios.length"
          :portfolios="portfolios"
          :selected-portfolio="portfolio"
        />
        <AccountLink />
      </div>
    </nav>

    <!-- Tabs -->
    <nav
      v-if="loggedIn && portfolio && hasPortfolioPermissions"
      v-sticky="true"
      sticky-offset="{ top: 0 }"
      sticky-z-index="8"
      class="secondary-nav"
    >
      <div class="secondary-nav-items">
        <router-link :to="{ name: 'portfolioBuildings', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.portfolio') }}
        </router-link>
        <router-link :to="{ name: 'report', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.report') }}
        </router-link>
        <router-link :to="{ name: 'scenarios', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.scenario') }}
        </router-link>
        <router-link :to="{ name: 'reida', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.reida') }}
        </router-link>
        <router-link
          v-if="getPortfolioPermission('EDIT_BUILDINGS')"
          :to="{ name: 'import', params: { portfolio_id: portfolio.id } }"
        >
          {{ $t('links.import') }}
        </router-link>
        <router-link :to="{ name: 'settings', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.settings') }}
        </router-link>
        <router-link :to="{ name: 'license', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.license') }}
        </router-link>
        <router-link v-if="isAdmin" :to="{ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } }">
          {{ $t('links.management') }}
        </router-link>
      </div>

      <div v-if="buildingSimulationQueue(this.portfolio.id).length > 0" class="main-layout-queue">
        <BuildingQueuedIndicator />
        <span>{{ $tc('simulation_pending', buildingSimulationQueue(portfolio.id).length) }}</span>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

import AccountLink from '@/components/shared/AccountLink.vue'
import PortfolioPicker from '@/components/shared/PortfolioPicker.vue'
import VersionNumber from '@/components/shared/VersionNumber.vue'
import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'

export default {
  components: {
    AccountLink,
    PortfolioPicker,
    VersionNumber,
    BuildingQueuedIndicator,
  },

  props: {
    portfolio: {
      type: Object,
    },
    portfolios: {
      type: Array,
    },
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
      loggedIn: 'account/loggedIn',
      isAdmin: 'account/admin',
      getPortfolioPermission: 'permissions/getPortfolioPermission',
      hasPortfolioPermissions: 'permissions/hasPortfolioPermissions',
    }),
  },
}
</script>

<style lang="scss" scoped>
.compass-header {
  position: relative;
  z-index: 9;

  & a {
    font-size: var(--font-s);
    font-weight: 400;
  }

  & #header-logo {
    display: inherit;
    flex: none;
  }

  & nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--spacing-m);
    align-items: center;
    padding: var(--spacing-s) var(--spacing-m);
  }

  & .primary-nav {
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--hairline-width) solid var(--hairline-color);
    padding: var(--spacing-m) var(--spacing-m);

    & .header-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-m);
    }
  }

  & .secondary-nav {
    height: var(--sticky-header-height);
    border-bottom: var(--hairline-width) solid var(--hairline-color);
    background-color: #fff;

    & .secondary-nav-items a {
      margin-right: var(--spacing-m);
    }

    & a.router-link-active {
      color: rgba(0, 0, 0, 1);
      border-bottom: 2px solid #000;
    }

    & .license-info {
      padding-left: var(--spacing-l);
      color: #a23939;
    }
  }

  & .main-layout-queue {
    display: flex;
    gap: 5px;
  }
}
</style>
