<template>
  <div :class="modalClasses">
    <div class="modal-mask" @click="$emit('close')" />

    <div class="modal-wrapper" :style="`min-width: min(${width}px, calc(100% - 20px))`">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wide: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: Number,
      default: 600,
      required: false,
    },
  },

  computed: {
    modalClasses() {
      return {
        modal: true,
        wide: this.wide,
      }
    },
  },
}
</script>

<style>
.modal {
  display: contents;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.6);
}

.modal-wrapper {
  display: block;
  /* min-width: 600px; */
  /* max-width: 100%; */
  max-height: 95%;
  overflow-y: auto;
  position: fixed;
  z-index: 12;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
}

.modal.wide .modal-wrapper {
  width: 90vw;
  max-width: 1600px;
}

.modal-container {
  width: 100%;
  padding: 24px 24px 40px 24px;
}
</style>
