<i18n>
{
  "de": {
    "adminUsersLink": "User Admin",
    "adminPortfoliosLink": "Portfolio Admin",
    "userManagement": "Benutzerverwaltung (Admin)",
    "login": "Anmelden",
    "logout": "Abmelden"
  }
}
</i18n>

<template>
  <Menu v-if="loggedIn" class="c-account-link" :buttonText="username">
    <template #options>
      <li>
        <router-link :to="{ name: 'portfolios' }">{{ username }}</router-link>
      </li>
      <template v-if="isAdmin">
        <li>
          <router-link :to="{ name: 'adminUsers' }">{{ $t('adminUsersLink') }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'adminPortfolios' }">{{ $t('adminPortfoliosLink') }}</router-link>
        </li>
      </template>
      <li>
        <a href="#" @click.prevent="onLogout">{{ $t('logout') }}</a>
      </li>
    </template>
  </Menu>
</template>

<script>
import { mapGetters } from 'vuex'

import Menu from '@/components/shared/menu/Menu.vue'

import compassApi from '@/services/compass-api.js'

export default {
  components: {
    Menu,
  },

  computed: {
    ...mapGetters({
      username: 'account/name',
      loggedIn: 'account/loggedIn',
      isAdmin: 'account/admin',
    }),
  },

  methods: {
    onLogout() {
      compassApi.logout()
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss">
.c-account-link {
  & .button {
    background: none;
    border: none;
  }
}
</style>
