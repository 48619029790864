<i18n>
{
  "de": {
    "selectPortfolio": "Portfolio auswählen",
    "loading": "Daten werden geladen"
  }
}
</i18n>

<template>
  <Menu v-if="portfolios" class="c-portfolio-picker" :buttonText="selectionTitle">
    <template #options>
      <li v-for="portfolio in portfolios" :key="portfolio.id">
        <router-link :to="{ name: 'portfolioBuildings', params: { portfolio_id: portfolio.id } }">{{
          portfolio.name
        }}</router-link>
      </li>
    </template>
  </Menu>
  <span v-else class="portfolio-picker">{{ $t('loading') }}</span>
</template>

<script>
import Menu from '@/components/shared/menu/Menu.vue'

export default {
  components: {
    Menu,
  },

  props: {
    selectedPortfolio: {
      type: Object,
    },
    portfolios: {
      type: Array,
    },
  },

  computed: {
    selectionTitle() {
      return this.selectedPortfolio ? this.selectedPortfolio.name : this.$t('selectPortfolio')
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-picker {
  & .button {
    background: none;
    border: none;
  }
}
</style>
